import { gql } from "@uplift-ltd/apollo";

export type {
  PracticeToolsProductQuery,
  PracticeToolsProductVariables,
} from "./__generated__/queries";

export const PRACTICE_TOOLS_HOMEPAGE_QUERY = gql`
  query PracticeToolsHomepage {
    products(where: { category: "private-practice-tools", visibility: VISIBLE }) {
      edges {
        node {
          id
          name
          slug
          privatePracticeTools {
            shortName
          }
        }
      }
    }
  }
`;

// TODO: see if hasPurchased can be removed and this query cached
export const PRACTICE_TOOLS_PRODUCTS_QUERY = gql`
  query PracticeToolsProducts($category: String) {
    products(where: { category: $category, visibility: VISIBLE }) {
      edges {
        node {
          id
          databaseId
          sku
          name
          priceUSD
          slug
          creditCost
          hasPurchased
          canPurchaseWithCredits
          privatePracticeTools {
            shortDescription
            note
            download
          }
          image {
            id
            mediaItemUrl
            mediaDetails {
              height
              width
            }
          }
          ... on VariableProduct {
            id
            sku
            name
            variations {
              edges {
                node {
                  id
                  databaseId
                  name
                  price
                  rawPrice: price(format: RAW)
                  slug
                  creditCost
                  canPurchaseWithCredits
                }
              }
            }
          }
          productTypes {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

// TODO: Separate out the user query and cache the view query
export const PRACTICE_TOOLS_PRODUCT_QUERY = gql`
  query PracticeToolsProduct($id: ID!, $idType: ProductIdTypeEnum!) {
    product(id: $id, idType: $idType) {
      id
      databaseId
      sku
      name
      priceUSD
      slug
      creditCost
      hasPurchased
      availability {
        isPurchasable
      }
      userDiscountsFromSubscription {
        id
        displayName
        percent
        fixedPrice
        subscriptionId
        productFeatureId
        usageLimit
        usageCount
        ceLimit
        ceCount
      }
      canPurchaseWithCredits
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        opengraphImage {
          id
          mediaItemUrl
        }
      }
      image {
        id
        mediaItemUrl
        mediaDetails {
          height
          width
        }
      }
      privatePracticeTools {
        summary
        longDescription
        note
        download
      }
      ... on VariableProduct {
        id
        sku
        name
        variations {
          edges {
            node {
              id
              name
              price
              slug
              creditCost
              canPurchaseWithCredits
              databaseId
            }
          }
        }
      }
      productTypes {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
